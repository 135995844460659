.items {
  display: flex;

  &__columns {
    @include invisible-scroll;
    scroll-snap-type: x mandatory;
    display: flex;
    flex: 1;
    align-items: stretch;
  }

  &__column {
    scroll-snap-align: start end;
    flex: 1;
    position: relative;
    margin: 0.5rem 0;
    gap: 0.5rem;
    padding: 1rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    @include mobile {
      min-width: 100vw;
    }

    &:not(:last-child) {
      border-right: 1px dashed $color-border;
      margin-right: 0;
    }

    h2 {
      font-variant: small-caps;
      font-weight: normal;
      font-size: 1.4rem;
      margin-bottom: 2rem;

      position: sticky;
      top: 0;
    }

    input[type=text] {
      @include reset-button;
      @include item;
      --background: white;
      --color: black;
      margin-top: 1rem;
      width: 100%;
    }
  }

  &__items {
    @include invisible-scroll;
    flex: 1;
  }

  .item:not(:hover) .item__colors {
    @include tablet {
      display: none;
    }
  }
}
