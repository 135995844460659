@import 'sass/reset';
@import 'sass/devices';
@import 'sass/helpers';

$color-border: #CCC;
$color-background: #EFEFEF;

@keyframes spin {
  from { transform: rotate(0turn); }
  to { transform: rotate(1turn); }
}

@mixin item {
  @include easing(120ms, background);
  font-size: 1.5rem;
  margin-bottom: 3px;
  padding: 5px 7px;
  height: 34px;
  border-radius: 3px;

  background: var(--background, black);
  color: var(--color, white);

  .has-filter &:not(.is-filtered):not(.has-focus):not(:hover) {
    --background: #{$color-background};
    --color: #000;
    outline: 1px solid $color-border;
    outline-offset: -1px;

    .item__content {
      opacity: 0.1;
    }

    .item__colors {
      display: none;
    }
  }
}

@import 'sass/base';

@import 'components/App';
@import 'components/Button';
@import 'components/Colors';
@import 'components/Icon';
@import 'components/Item';
@import 'components/Items';
@import 'components/Login';
@import 'components/Timeline';
