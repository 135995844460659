.colors {
  @include invisible-scroll;
  display: flex;
  padding: 1.2rem;
  gap: 3px;

  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;

  form.color {
    position: relative;
    flex: 1;

    @include mobile {
      min-width: 40%;
    }

    input[type=text] {
      @include reset-button;
      @include item;
      width: 100%;
      height: 2rem;
      font-size: 1.2rem;
      font-variant: small-caps;
      text-transform: lowercase;
      padding-top: 4px;

      padding-right: 2rem;
    }

    input[type=checkbox] {
      display: none;
    }

    label {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      top: 1px;
      right: 5px;

      &::after {
        @include inset(-5px);
        content: '';
      }
    }

    input:checked + label .icon.icon--preview,
    input:not(:checked) + label .icon.icon--hidden {
      display: none;
    }

    &:not(:hover):not(.is-filtered) label {
      display: none;
    }
  }
}
