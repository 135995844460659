.timeline {
  --zoom: 1;

  position: relative;
  display: flex;
  flex-direction: column;

  &__scrollable {
    @include invisible-scroll;
    flex: 1;
  }

  &__voids {
    @include invisible-scroll;
    display: flex;
    border-top: 1px solid $color-border;
    padding: 1.5rem;
    gap: 3px;
  }

  // DEBUG
  &:not(.has-sorting) &__voids:hover {
    flex-direction: column;
  }

  &__void {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;

    &::before {
      // content: attr(data-label);
      white-space: nowrap;
      font-variant: small-caps;
      font-weight: normal;
      text-transform: lowercase;
      font-size: 1.4rem;
      min-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item {
      --start: 0 !important;
      --end: 0 !important;
      min-width: 20rem;
      max-width: 20rem;

      .item__resize-handle {
        display: none;
      }
    }
  }

  &__voids:not(:hover) &__void::before {
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: min-content;
    min-height: 100%;
  }

  &__months {
    z-index: 1;
    position: sticky;
    top: 0;
    display: flex;
    padding: 0 1.5rem;
    height: 4.9rem;
    background: $color-background;
  }

  &__month {
    display: flex;
    position: relative;

    border-left: 1px solid $color-border;
    &:first-child { border: 0; }
    &:first-child::before { margin-left: -1.5rem; }

    &[data-month]::before {
      content: attr(data-month);

      position: absolute;
      z-index: 1;

      font-variant: small-caps;
      font-weight: normal;
      text-transform: lowercase;
      font-size: 1.4rem;

      top: 0;
      width: 100%;
      padding: 1.5rem;
      background: $color-background;
    }
  }

  &__week {
    flex: 1;
    min-width: Max(calc((100vw - 4rem) / (12 * 4)), calc(5rem * var(--zoom, 1)));
  }

  &__ticks {
    @include inset(0);
    pointer-events: none;
    display: flex;
    padding: 0 1.5rem;
    bottom: 0rem;
  }

  &__ticks &__week {
    border-left: 1px dashed $color-border;
    &:first-child { border: 0; }
  }

  &__current {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 2px solid #ff6188;
    left: calc(100% * var(--start));
  }

  &__items {
    flex: 1;
    padding: 0 1.5rem 1.5rem;
  }

  // Artificially add a last full-width item so that sortablejs allow dropping
  // new items anywhere below it
  &__items .item.fake {
    height: 0;
    padding: 0;
    margin: 0;
  }

  // Artificially stretch item to viewport width while sorting, so that
  // sortablejs can trigger anywhere on the item line, not only on its bbox
  &.has-sorting &__items .item::after {
    content: '';
    position: absolute;
    top: 0;
    left: -9999px;
    right: -9999px;
    bottom: 0;
  }

  .item {
    margin-left: calc(100% / 48 * (var(--start)));
    width: calc(100% / 48 * (var(--end) - var(--start)));

    &.is-small .item__content:hover {
      width: fit-content;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }
  }

  &__items .item.sortable-ghost {
    width: 100%;
    margin-left: 0;
    transform: scale(1, 0.1) !important;
  }

  &__items .item__content {
    position: sticky;
    left: 1rem;
    padding-right: 1.5rem;
    max-width: min-content;
  }
}
