.app {
  margin: 0 auto;

  display: flex;
  flex-direction: column;


  .items, .timeline {
    scroll-snap-align: start end;
    height: calc(100vh - 4.8rem);
  }

  .colors {
    margin-top: -1px;
  }

  .icon--dirty {
    @include easing(120ms, opacity);
    z-index: 2;
    position: fixed;
    right: 1rem;
    top: 2rem;
    animation: spin 1s linear infinite;
    opacity: 0;
    pointer-events: none;
  }

  &.is-dirty .icon--dirty { opacity: 1; }

  &.is-authenticated .login,
  &:not(.is-authenticated) > *:not(.login) {
    display: none;
  }
}
