.login {
  @include inset(0, fixed);

  display: grid;
  place-items: center;

  form {
    position: relative;
  }

  &__message {
    font-weight: normal;
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  input[type=password] {
    @include reset-button;
    position: relative;
    color: black;
    background-color: white;
    padding: 0.5rem;
    border: 1px solid $color-border;
    width: 100%;

    text-align: center;
    font-size: 3rem;

    &:focus {
      outline: 1px solid black;
    }
  }

  &__error {
    position: absolute;
    color: #d16464;
    margin-top: 1rem;
  }
}
