html {
  @include no-select;
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-snap-type: y mandatory;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: $color-background;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.4rem;
  line-height: 1.3;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

input[type=text]:placeholder-shown {
  font-style: italic;
  color: #999 !important;
}

input[type=text]:focus::placeholder {
  color: transparent;
}
