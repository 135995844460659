.item {
  @include item;
  position: relative;
  display: flex;

  &__content {
    display: flex;
    width: 100%;
    margin: -5px 0;
  }

  .button, .icon {
    width: 1.2rem;
    height: 1.2rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__handle {
    @include easing(120ms, opacity);
    margin-top: 11px;
    min-width: 1.2rem;
    .item:not(:hover) & { opacity: 0.6; }
  }

  &__buttons {
    display: flex;
  }

  &__buttons .button {
    margin-top: 11px;
    padding: 0 1.3rem;
  }

  &:not(:hover) &__buttons {
    @include devices (tablet, desktop) {
      display: none;
    }
  }

  &.sortable-ghost {
    opacity: 0.1;
    * {
      visibility: hidden;
    }
  }

  &.sortable-chosen {
    width: auto;
  }

  &__resize-handle {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;

    // Increase hitbox
    width: 1rem;
    cursor: ew-resize;

    @include mobile {
      display: none;
    }

    &[data-side='before'] { left: -0.5rem; }
    &[data-side='after'] { right: -0.25rem; }
  }

  &__handle {
    cursor: move;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }

  &.has-focus {
    background: white;
    color: black;
  }

  &__name {
    cursor: text;
    flex: 1;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -2px;

    // Fix contenteditable not working on Safari
    -webkit-user-select: text;
    user-select: text;

    &:focus {
      outline: 0;
      text-overflow: none;
    }
  }

  &.is-resizing {
    @include easing(120ms, transform);
    transform: scale(1, 0.1);
    * {
      visibility: hidden;
    }
  }

  &__colors {
    input[type='checkbox'] {
      @include reset-button;
      position: relative;
      cursor: pointer;
      margin-top: 10px;
      height: 1.4rem;
      width: 1.4rem;
      margin-left: 3px;
      border-radius: 100%;
      background: var(--color);

      // Add a dot inside checked colors
      &:checked::after {
        content: '';
        background: black;
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        top: 50%;
        border-radius: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  // Hide unchecked colors when not hovering parent item
  &:not(:hover) &__colors *:not(:checked) { display: none; }

  // Hide dot on checked colors when not hovering parent item
  &:not(:hover) &__colors *:checked::after {
    display: none;
  }
}
